import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import Layout from '../../../../modules/layout';
import { useJurorFilters } from '../../../../hooks/useJurorFilters';
import TeaserItem from '../../../../components/jury/filterList/components/juryTeaserItem';
import Search from '../../../../components/ui/filterSearch';
import ResultsList from '../../../../components/ui/resultsList';
import { useLocale } from '../../../../context/locale.context';

const JuryOverview = () => {
  const { locale } = useLocale();
  const [query, _] = useQueryParams({
    awardId: ArrayParam,
    yearId: ArrayParam,
    countryId: ArrayParam,
    sort: StringParam,
    find: StringParam,
  });

  const { allContentfulSearchChip, allContentfulMicrocopy } = useStaticQuery(
    graphql`
      query {
        allContentfulSearchChip(
          filter: {
            content_search: {
              elemMatch: {
                page: {
                  elemMatch: { slug: { eq: "if-design-award-and-jury" } }
                }
              }
            }
          }
        ) {
          nodes {
            behaviour
            tag
            label
            queryParameters
            node_locale
          }
        }
        allContentfulMicrocopy(
          filter: { key: { eq: "placeholder.whatDoYouWantToExplore" } }
        ) {
          nodes {
            value
            node_locale
          }
        }
      }
    `
  );

  const localizedChips = {
    nodes: allContentfulSearchChip.nodes.filter(
      (chip) =>
        chip.node_locale === locale && chip.behaviour !== 'Query Parameter'
    ),
  };

  const onSubmit = (searchTerm) => {
    navigate(searchTerm, {
      replace: true,
    });
  };

  const resetSearchTerm = () => navigate('?', { replace: true });

  const queryFilters = {
    awards: query.awardId ? query.awardId.map(Number) : [1],
    country: query.countryId ? query.countryId.map(String) : [],
    time: query.yearId ? query.yearId.map(Number) : [2],
    sort: query.sort ? query.sort : 'desc',
    find: query.find,
  };

  return (
    <Layout
      title={'title.juryOverview'}
      description={'desc.juryOverview'}
      slug={'social-engagement/student-award/jury-overview'}
    >
      <Search
        onSubmit={onSubmit}
        resetSearchTerm={resetSearchTerm}
        initSearchTerm={query.find}
        chips={localizedChips.nodes}
        type="Jury"
        placeholder={
          allContentfulMicrocopy.nodes.find(
            (copy) => copy.node_locale === locale
          ).value
        }
      />
      <ResultsList
        queryFilters={queryFilters}
        searchTerm={query.find}
        resetSearchTerm={resetSearchTerm}
        filtersHook={useJurorFilters}
        itemRenderer={TeaserItem}
        countLabelKey="label.countProfiles"
        filtersApiParam="student"
        type="juror"
      />
    </Layout>
  );
};

export default JuryOverview;
